<template>
<main id="main">
  <div class="wrap1160">
    <section id="guide">
      <h2 class="titPage"><span>{{$t("GUIDE_PAGE_TITLE_1")}}/<br class="only_sp">{{$t("GUIDE_PAGE_TITLE_2")}}</span></h2>
      <ul class="step">
        <li class="step-1">
          <h3><em>STEP1</em>{{$t("GUIDE_PAGE_STEP1_TITLE")}}</h3>
          <div class="conts">
            <!-- <div class="outline">
              <div class="out-txt">
                <p>
                  {{$t("GUIDE_PAGE_STEP1_DESCRIPTION_1")}}<br>
                  {{$t("GUIDE_PAGE_STEP1_DESCRIPTION_2")}}<br>
                  {{$t("GUIDE_PAGE_STEP1_DESCRIPTION_3")}}
                </p>
              </div>
            </div>
            <section id="entry-info">
              <dl>
                <dt v-html="$t('MEMBER_REQUEST_DOCUMENT_EXPLANATION_MESSAGE')">
                </dt>
                <dd>
                  <p class="jp-document" v-html="$t('MEMBER_REQUEST_DOCUMENT_TITLE')"></p>
                  <ol>
                    <li v-for="(constant, index) in samleFiles.filter(x => $i18n.locale === 'ja' || x.sort_order !== 1)"
                      :style="constant.sort_order === 2 ? 'width: 37%;' : 'width: 30%;'">
                      <a :href="'#modal-open-' + (constant.sort_order ? constant.sort_order : (index + 1))">
                        <p class="entry-info-item">
                          <span>{{index+1}}</span>
                          <label class="bold-text" v-html="constant.value1"></label>
                        </p>
                        <img :src="'./' + constant.file_url" alt="" style="max-height:200px">
                        <p v-if="$i18n.locale === 'ja' || constant.sort_order !== 2" class="kome entry-info-note">{{constant.value2}}</p>
                      </a>
                    </li>
                  </ol>
                </dd>
              </dl>
              <p v-if="$i18n.locale === 'ja'" class="entry-info-att" v-html="$t('MEMBER_REQUEST_FORM_CAUTION_MESSAGE')"></p>
              <div class="remodal" :data-remodal-id="'modal-open-' + (sampleFile1 && sampleFile1.sort_order ? sampleFile1.sort_order : 1)">
                <button data-remodal-action="close" class="remodal-close"></button>
                <img :src="'./' + sampleFile1.file_url" alt="">
              </div>
              <div class="remodal" :data-remodal-id="'modal-open-' + (sampleFile2 && sampleFile2.sort_order ? sampleFile2.sort_order : 2)">
                <button data-remodal-action="close" class="remodal-close"></button>
                <img :src="'./' + sampleFile2.file_url" alt="">
              </div>
              <div class="remodal" :data-remodal-id="'modal-open-' + (sampleFile3 && sampleFile3.sort_order ? sampleFile3.sort_order : 3)">
                <button data-remodal-action="close" class="remodal-close"></button>
                <img :src="'./' + sampleFile3.file_url" alt="">
              </div>
            </section>
            <div class="taC btn-signup">
              <a @click="$routerGo($define.PAGE_NAMES.NEW_MEMBER_REGISTRATION_PAGE_NAME)" class="btnBsc-Black">{{$t("LINK_NEW_MEMBER_REGISTRATION_APPLICATION_FREE")}}<span></span><img src="@/assets/img/common/ic_arrow_white.svg" alt=""></a>
            </div>
            <div class="judge">
              <div class="outline">
                <div class="out-txt">
                  <p class="fontB txtCoCor">{{$t("GUIDE_PAGE_STEP1_NOTE_TITLE_1")}}</p>
                  <p class="mt20">
                    <span class="fontB">{{$t("GUIDE_PAGE_STEP1_NOTE_DESCRIPTION_1")}}</span><br>
                    {{$t("GUIDE_PAGE_STEP1_NOTE_DESCRIPTION_2")}}
                  </p>
                </div>
                <div class="out-img">
                  <img src="@/assets/img/auction/img_step1.png" alt="">
                </div>
              </div>
            </div> -->
          </div>
        </li>

        <li class="step-2">
          <h3><em>STEP2</em>{{$t("GUIDE_PAGE_STEP2_TITLE")}}</h3>
          <div class="conts">

            <!-- <ul id="tab_btn">
              <li class="tab-auction"><a href="#tab1">WEB <br class="only_sp">{{$t("GUIDE_PAGE_STEP2_TAB_1")}}</a></li>
              <li class="tab-tender"><a href="#tab2">WEB <br class="only_sp">{{$t("GUIDE_PAGE_STEP2_TAB_2")}}</a></li>
              <li class="tab-stock"><a href="#tab2">{{$t("GUIDE_PAGE_STEP2_TAB_3")}}</a></li>
            </ul>
            <ul id="tab_info">
              <li class="tab_i-auction">
                <h4>{{$t("GUIDE_PAGE_STEP2_TAB_1_TITLE_1")}}</h4>
                <div class="schedule">
                  <figure>
                    <img :src="calendarImage.file_url" alt="">
                  </figure>
                  <div class="schedule_txt">
                    <p>
                      {{$t("GUIDE_PAGE_STEP2_TAB_1_1_DESCRIPTION_1")}}<br>
                      {{$t("GUIDE_PAGE_STEP2_TAB_1_1_DESCRIPTION_2")}}
                    </p>
                    <div class="schedule_btn">
                      <a @click="$routerGo($define.PAGE_NAMES.TOP_PAGE_NAME, null, null, 'auction-schedule')" class="btnBsc-Black btn-list">{{$t("GUIDE_PAGE_STEP2_TAB_1_1_DESCRIPTION_3")}}<img src="@/assets/img/common/ic_arrow_white.svg" alt=""></a>
                    </div>
                  </div>
                </div>

                <h4>{{$t("GUIDE_PAGE_STEP2_TAB_1_2_TITLE_1")}}</h4>
                <p>{{$t("GUIDE_PAGE_STEP2_TAB_1_2_DESCRIPTION_1")}}</p>

                <h4>{{$t("GUIDE_PAGE_STEP2_TAB_1_3_TITLE_3")}}</h4>
                <p>{{$t("GUIDE_PAGE_STEP2_TAB_1_3_DESCRIPTION_1")}}</p>

                <p class="assist-h4">{{$t("GUIDE_PAGE_STEP2_TAB_1_4_TITLE_4")}}</p>
                <h4>{{$t("GUIDE_PAGE_STEP2_TAB_1_5_TITLE_5")}}</h4>
                <p>{{$t("GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_1")}}</p>
                <p class="method">{{$t("GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_2")}}</p>

                <div class="bid-graph">
                  <p class="ex-txt">{{$t("GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_3")}}<br class="only_sp">{{$t("GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_4")}}<br class="only_sp">{{$t("GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_5")}}</p>
                  <picture>
                    <source media="(max-width:767px)" :srcset="auctionSpImage.file_url">
                    <img :src="auctionPcImage.file_url" alt="">
                  </picture>
                  <p class="ex-fds">
                    {{$t("GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_7")}}<br>
                    {{$t("GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_8")}}
                  </p>
                </div>

                <a @click="$routerGo($define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME)" class="btnBsc-Black btn-list">{{$t("GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_9")}}<img src="@/assets/img/common/ic_arrow_white.svg" alt=""></a>
              </li>

              <li class="tab_i-tender">
                <h4>{{$t("GUIDE_PAGE_STEP2_TAB_2_TITLE_1")}}</h4>
                <div class="schedule">
                  <figure>
                    <img :src="calendarImage.file_url" alt="">
                  </figure>
                  <div class="schedule_txt">
                    <p>
                      {{$t("GUIDE_PAGE_STEP2_TAB_2_1_DESCRIPTION_1")}}<br>
                      {{$t("GUIDE_PAGE_STEP2_TAB_2_1_DESCRIPTION_2")}}
                    </p>
                    <div class="schedule_btn">
                      <a @click="$routerGo($define.PAGE_NAMES.TOP_PAGE_NAME, null, null, 'auction-schedule')" class="btnBsc-Black btn-list">{{$t("GUIDE_PAGE_STEP2_TAB_2_1_DESCRIPTION_3")}}<img src="@/assets/img/common/ic_arrow_white.svg" alt=""></a>
                    </div>
                  </div>
                </div>

                <h4>{{$t("GUIDE_PAGE_STEP2_TAB_2_2_TITLE_1")}}</h4>
                <p>{{$t("GUIDE_PAGE_STEP2_TAB_2_2_DESCRIPTION_1")}}</p>

                <h4>{{$t("GUIDE_PAGE_STEP2_TAB_2_3_TITLE_3")}}</h4>
                <p>{{$t("GUIDE_PAGE_STEP2_TAB_2_3_DESCRIPTION_1")}}</p>

                <p class="assist-h4">{{$t("GUIDE_PAGE_STEP2_TAB_2_4_TITLE_4")}}</p>
                <p class="method">{{$t("GUIDE_PAGE_STEP2_TAB_2_5_DESCRIPTION_1")}}</p>
                <p class="mt15">{{$t("GUIDE_PAGE_STEP2_TAB_2_5_DESCRIPTION_2")}}</p>
                <p class="kome">{{$t("GUIDE_PAGE_STEP2_TAB_2_5_DESCRIPTION_3")}}</p>

                <a @click="$routerGo($define.PAGE_NAMES.TENDER_LIST_PAGE_NAME)" class="btnBsc-Black btn-list">{{$t("GUIDE_PAGE_STEP2_TAB_2_5_DESCRIPTION_4")}}<img src="@/assets/img/common/ic_arrow_white.svg" alt=""></a>
              </li>

              <li class="tab_i-stock">
                <h4>{{$t("GUIDE_PAGE_STEP2_TAB_3_TITLE_1")}}</h4>
                <p>{{$t("GUIDE_PAGE_STEP2_TAB_3_1_DESCRIPTION_1")}}</p>

                <h4>{{$t("GUIDE_PAGE_STEP2_TAB_3_TITLE_2")}}</h4>
                <p>{{$t("GUIDE_PAGE_STEP2_TAB_3_1_DESCRIPTION_2")}}</p>

                <a @click="$routerGo($define.PAGE_NAMES.STOCK_LIST_PAGE_NAME)" class="btnBsc-Black btn-list">{{$t("GUIDE_PAGE_STEP2_TAB_3_1_DESCRIPTION_3")}}<img src="@/assets/img/common/ic_arrow_white.svg" alt=""></a>
              </li>
            </ul> -->
          </div>
        </li>

        <li class="step-3">
          <h3><em>STEP3</em>{{$t("GUIDE_PAGE_STEP3_TITLE")}}</h3>
          <div class="conts">
            <div class="outline">
              <div class="out-txt">
                <!-- <p>{{$t("GUIDE_PAGE_STEP3_DESCRIPTION_1")}}</p>
                <p class="kome">{{$t("GUIDE_PAGE_STEP3_DESCRIPTION_2")}}</p> -->
              </div>
              <div class="out-img">
                <img src="@/assets/img/auction/img_step3.png" alt="">
              </div>
            </div>
          </div>
        </li>

        <li class="step-4">
          <h3><em>STEP4</em>{{$t("GUIDE_PAGE_STEP4_TITLE")}}</h3>
          <div class="conts">
            <div class="outline">
              <div class="out-txt">
                <!-- <p>
                  {{$t("GUIDE_PAGE_STEP4_DESCRIPTION_1")}}<br>
                  {{$t("GUIDE_PAGE_STEP4_DESCRIPTION_2")}}
                </p> -->
              </div>
              <div class="out-img">
                <img src="@/assets/img/auction/img_step4.png" alt="">
              </div>
            </div>
          </div>
        </li>

        <li class="step-5">
          <h3><em>STEP5</em>{{$t("GUIDE_PAGE_STEP5_TITLE")}}</h3>
          <div class="conts">
            <div class="outline">
              <div class="out-txt">
                <!-- <p>{{$t("GUIDE_PAGE_STEP5_DESCRIPTION_1")}}</p>
                <p class="att mt20">
                  {{$t("GUIDE_PAGE_STEP5_DESCRIPTION_2")}}<br>
                  {{$t("GUIDE_PAGE_STEP5_DESCRIPTION_3")}}
                </p> -->
              </div>
              <div class="out-img">
                <img src="@/assets/img/auction/img_step5.png" alt="">
              </div>
            </div>
          </div>
        </li>

        <li class="step-6">
          <h3><em>STEP6</em>{{$t("GUIDE_PAGE_STEP6_TITLE")}}</h3>
          <div class="conts">
            <div class="outline">
              <div class="out-txt">
                <!-- <p>
                  {{$t("GUIDE_PAGE_STEP6_DESCRIPTION_1")}}<br>
                  {{$t("GUIDE_PAGE_STEP6_DESCRIPTION_2")}}
                </p>
                <p class="mt20">
                  <span class="fontB">{{$t("GUIDE_PAGE_STEP6_DESCRIPTION_3")}}</span><br>
                  {{$t("GUIDE_PAGE_STEP6_DESCRIPTION_4")}}<br>
                  {{$t("GUIDE_PAGE_STEP6_DESCRIPTION_5")}}
                </p> -->
              </div>
              <div class="out-img">
                <img src="@/assets/img/auction/img_step6.png" alt="">
              </div>
            </div>
          </div>
        </li>
      </ul>
    </section>
  </div>
</main>
</template>

<script>
import Methods from '@/api/methods'
import useLoading from '@/stores/useLoading'
import {defineComponent} from '@vue/composition-api'
export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    return {
      loadingStore
    }
  },
  data() {
    return {
      scripts : [
        'remodal.min.js',
        'tab-hash.js'
      ],
      samleFiles     : [],
      sampleFile1    : {},
      sampleFile2    : {},
      sampleFile3    : {},
      calendarImage  : {},
      auctionPcImage : {},
      auctionSpImage : {}
    }
  },
  mounted() {
    Methods.addExternalScripts(this.scripts)
  },
  methods : {
    getGuideConstants() {
      this.$store.state.loading = true
      Methods.apiExcute('get-guide-constants', this.$i18n.locale).then(data => {
        this.$store.state.loading = false
        this.samleFiles = this.padArray([], 3)
        this.sampleFile1 = this.samleFiles[0]
        this.sampleFile2 = this.samleFiles[1]
        this.sampleFile3 = this.samleFiles[2]
        this.calendarImage = data.find(constant => constant.key_string === 'GUIDE_WEB_CALENDAR_IMAGE')
        this.auctionPcImage = data.find(constant => constant.key_string === 'GUIDE_WEB_AUCTION_PC_IMAGE')
        this.auctionSpImage = data.find(constant => constant.key_string === 'GUIDE_WEB_AUCTION_SP_IMAGE')
      })
        .catch(error => {
          this.$store.state.loading = false
          this.errors = Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    padArray(array, length) {
      for (let i = 0;i < length;i++) {
        array.push({})
      }
      array.splice(length)
      return array
    }
  },
  created() {
    this.getGuideConstants()
  },
  watch : {
    '$i18n.locale'() {
      this.getGuideConstants()
    }
  }
})
</script>
<style lang="scss">
  .remodal-wrapper:after {
    vertical-align: middle;
  }
  #main #entry-info dl dd ol {
    justify-content: space-evenly !important;
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
